import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  vehicleContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '1rem',
    '@media (max-width: 750px)': {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  vehicleBtnOptions: {
    marginLeft: '1rem',
    '@media (max-width: 750px)': {
      marginTop: '0.75rem',
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      marginLeft: 0,
    },
  },
  errorBox: {
    maxWidth: '36.2rem',
    backgroundColor: 'rgba(44, 148, 167, 0.1)',
    color: theme.palette.common.black,
    ...theme.form.labels.selectFieldSmall.hint,
    fontWeight: 500,
    lineHeight: '1.5rem',
    padding: '1rem',
  },
  vehicleTextField: {
    marginLeft: '1rem',
    '& input': {
      textTransform: 'uppercase',
    },
  },
  vehicleDeleteDesktop: {
    '@media (max-width: 750px)': {
      display: 'none',
    },
  },
  vehicleDeleteMobile: {
    '@media (min-width: 750px)': {
      display: 'none',
    },
    '& button': {
      marginTop: '0.75rem',
      marginLeft: 0,
    },
  },
  clearBtn: {
    marginLeft: 0,
  },
  anchorText: {
    color: theme.palette.common.black,
    fontWeight: 'bold',
  },
  registrationNumberLabel: {
    ...theme.form.labels.inputField.label,
    color: theme.palette.dark[800],
    marginBottom: '1rem'
  },
  lookupField: {
    minWidth: '17rem',
  },
  vehicleLookUpContainer: {
    border: `2px solid ${theme.palette.primary.dark}`,
    borderRadius: '5px',
    maxWidth: '40rem',
    marginBottom: '3rem',
  },
  vehicleLookUpGrid: {
    padding: '1rem 1.75rem',
  },
  bottomHighlight: {
    color: theme.palette.common.black,
    padding: '0rem 1.25rem',
    backgroundColor: '#F8F9FA',
  },
  lookupContainer: {
    display: 'flex',
    '@media (max-width: 500px)': {
      flexDirection: 'column'
    }
  },
  lookupBtn: {
    marginLeft: '2rem',
    minWidth: '17rem',
    '@media (max-width: 500px)': {
      marginTop: '1rem',
      marginLeft: '0rem',
    }
  },
  responsivePadding: {
    '@media (max-width: 430px)': {
      paddingTop: '1rem',
      paddingLeft: '1rem',
    },
    '@media (min-width: 600px)': {
      paddingLeft: '2rem',
    },
  },
  regBoxContainer: {
    display: 'flex',
    paddingRight: '1rem',
  },
  regBox: {
    minWidth: '10rem',
    padding: '1rem',
    paddingRight: '1rem',
    border: `2px solid ${theme.palette.primary.dark}`,
    borderRadius: '5px',
  },
  maxWidth30: {
    maxWidth: '30rem'
  },
}));
