import React, { useEffect, useContext } from 'react';
import PageTemplate from '../../templates/PageTemplate';
import { useStyles } from './CoverDetailsStyles';
import { Grid, Box } from '@material-ui/core';
import { StepContext, IStepData, steps, Step } from '../../contexts/StepContext';
import {
  DocumentTitle,
  Typography,
  ButtonPrimary,
  RadioButtonGroup,
  Divider,
  LinkPrimary,
  SquareRadioGroup,
} from '../../components/atoms';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import clsx from 'clsx';
import * as yup from 'yup';
import axios from 'axios';
import CoverDetailsSchema from './CoverDetailsSchema';
import uuid from 'uuid';
import { determineAvailableOptions, populateComparisonTableWithPricing } from '../../utils/coverDetailsUtils';
import { buildAdditionalOptions } from '../../utils/optionNameUtils';

declare global {
  interface Window {
    gtag: any;
  }
}

interface IParams {
  id: string;
}
export const CoverDetails: React.FC = () => {
  const { activeStep, updateActiveStep, loading, updateLoading, updateData, data, updateShowHero, updateShowStepper } = useContext(StepContext);
  const { id } = useParams<IParams>();
  const getDocument = (filePath: string) => window.open(filePath);

  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();

  const redirectNewUserToCoverDetails = (newUuid) => {
    if (location.pathname === '/' || location.pathname === '/coverOptions' || location.pathname === '/coverOptions/') {
      history.replace(`/coverOptions/${newUuid}`);
    }
  };

  useEffect(() => {
    updateActiveStep(1);

    const script = document.createElement('script');
    script.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('config', 'DC-3535201');
    `;
    document.head.appendChild(script);

    // Trigger GTM DoubleClick event
      window.gtag('event', 'conversion', {
        allow_custom_scripts: true,
        send_to: 'DC-3535201/green308/green004+unique',
      });

    const getOptionsData = async () => {
      try {
        const { data: options } = await axios.get(
          `${process.env.REACT_APP_SERVERLESS_BASE_URL}/${process.env.REACT_APP_OPTIONS_ENDPOINT}`,
        );

        const stateDataObjectInternal = {
          ...data,
          options: options.Items,
          priceData: populateComparisonTableWithPricing(options.Items),
        };

        updateData(stateDataObjectInternal);
        reset(stateDataObjectInternal);
        updateLoading(false);
      } catch (err) {
        console.log(err);
      }
    };

    if (!id) {
      const generatedUuid = uuid.v4();
      redirectNewUserToCoverDetails(generatedUuid);
    }

    if (window['lpTag']) window['lpTag'].section = ['Direct Line', 'Mayday', 'Cover Details Page'];

    getOptionsData();
    updateShowHero(true);
    updateShowStepper(true);
  }, [id]);

  const { NumberOfVehicles, HomeCall, CoverAtHomeResultsSingle, CoverAtHomeResultsDual, PersonalCover } = CoverDetailsSchema;

  const schema = yup.object().shape({
    numberOfVehicles: yup
      .string()
      .required('Tell us the number of vehicles you want to cover.')
      .oneOf(['Single', 'Dual'], 'Please select one of the options.'),
    vehicleCountSquareRadioButtonGroup: yup
      .string()
      .required('Please select an option.')
      .oneOf(['Selected'], 'Please select an option.'),
    homeCall: yup
      .string()
      .required('Tell us if you need breakdown cover at home.')
      .oneOf(['True', 'False'], 'Tell us if you need breakdown cover at home.'),
    coverAtHomeResults: yup
      .string()
      .when('carCaravan', {
        is: true,
        then: yup
          .string()
          .when('homeCall', {
            is: 'True',
            then: yup
              .string()
              .required('Please select an option.')
              .oneOf(['First', 'Second'], 'Please select an option.'),
          })
      }),
    personalCover: yup
      .string()
      .required('Let us know if you need Personal Cover.')
      .oneOf(['True', 'False'], 'Let us know if you need Personal Cover.'),
  });

  const {
    handleSubmit,
    setValue,
    trigger,
    control,
    formState: { errors },
    watch,
    reset,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      ...data,
      coverAtHomeResults: '',
      numberOfVehicles: '',
      homeCall: '',
      vehicleCountSquareRadioButtonGroup: '',
    },
    shouldFocusError: true,
    shouldUnregister: false,
  });

  const handleSelectedVehicleChange = (option) => {
    setValue('motorHomeCampervan', option.motorHomeCampervan);
    data.motorHomeCampervan = option.motorHomeCampervan;

    setValue('carCaravan', option.carCaravan);
    data.carCaravan = option.carCaravan;

    setValue('carMotorbike', option.carMotorbike);
    data.carMotorbike = option.carMotorbike;

    if (!option.motorHomeCampervan && !option.carCaravan && !option.carMotorbike) {
      setValue('vehicleCountSquareRadioButtonGroup', '');
    } else {
      setValue('vehicleCountSquareRadioButtonGroup', 'Selected');
    }

    if (!option.carCaravan) {
      setValue('coverAtHomeResults', '');
    } else {
      trigger('coverAtHomeResults');
    }
  };

  const numberOfVehicles = watch('numberOfVehicles');
  const homeCall = watch('homeCall');
  const personalCover = watch('personalCover');
  const coverAtHomeResults = watch('coverAtHomeResults');

  const onChangeHomeCall = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const homeCallCheck = e.target.value;
    if (homeCallCheck === 'False') {
      setValue('coverAtHomeResults', '');
    }
  };

  const onChangePersonalCover = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const isPersonalCover = e.target.value;
    if (isPersonalCover === 'False'){
      setValue('partnerInitial', '');
      setValue('partnerSurname', '');
    }
  };

  const onChangeVehicleNumber = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const numberOfVehiclesCheck = e.target.value;

    data.motorHomeCampervan = false;
    data.carCaravan = false;
    data.carMotorbike = false;

    trigger('motorHomeCampervan');
    trigger('carCaravan');
    trigger('carMotorbike');

    setValue('numberOfVehicles', numberOfVehiclesCheck);
    setValue('vehicleCountSquareRadioButtonGroup', '');

    // Wipe additional vehicle data on selection of Single Vehicle
    if (numberOfVehiclesCheck == 'Single') {
      setValue('additionalVehicleRegistrationNumber', '');
      setValue('additionalVehicleMake', '');
      setValue('additionalVehicleModel', '');
      setValue('additionalVehicleAge', 0);
    }
  };

  const onSubmit = (stepData: IStepData) => {
    updateActiveStep(activeStep + 1);
    updateData({
      ...data,
      ...stepData,
      quote: id,
      homeCall: homeCall,
      personalCover: personalCover,
      additionalOptions: buildAdditionalOptions(numberOfVehicles, personalCover),
      availableOptionsFromSelections: determineAvailableOptions(
        homeCall,
        numberOfVehicles,
        data.motorHomeCampervan,
        data.carCaravan,
        data.carMotorbike,
        coverAtHomeResults === 'First' ? true : false
      ),
    });
    history.push(steps[Step.YOUR_COVER].url);
  };

  if (loading) return <></>;

  return (
    <PageTemplate>
      <DocumentTitle title={`DLG ${process.env.REACT_APP_SITE_ID?.toUpperCase()} - Cover Details`} />
      <Controller control={control} name="coverType" defaultValue={data.coverType} render={() => <></>} />
      <Grid container className={classes.stepper}>
        <Box>
          <Grid item xs={12} lg={12} className="pb1">
            <Typography variant="h2">Let&apos;s find the cover that&apos;s right for you.</Typography>
          </Grid>
          <Grid item xs={12} className="pb1">
            <Typography>
              With all our policies you&apos;ll get cover for a year, and we&apos;ll be there to help you 24 hours a
              day, every day.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <LinkPrimary onClick={() => getDocument('/pdfs/MAYDAY-Policy-booklet.pdf')}>
              You can view our policy booklet here.
            </LinkPrimary>
          </Grid>
        </Box>
      </Grid>

      <Grid container className={classes.gridMainContainer}>
        <Grid item xs={12} lg={12} className={classes.gridMain}>
          <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <Box className="py2">
              <Typography className={classes.responsiveHeading} variant="body1">
                We&apos;ll ask you a few quick questions to help you find the right cover. Make sure you answer
                everything accurately or to the best of your knowledge, or your policy may not be valid.
              </Typography>
            </Box>
            <Box className={clsx(classes.bgLightBlue, 'p1')}>
              <Typography variant="h3">How many vehicles do you want to cover?</Typography>
            </Box>
            <Box className="py2">
              <Typography variant="body1">
                If you have a car and a caravan we class this as <span className="fs-elliot-bold">one vehicle</span> (as
                there&apos;s only one engine).
              </Typography>
              <Typography variant="body1">
                If you need breakdown cover for <span className="fs-elliot-bold">three or more vehicles</span>,
                please call us on{' '}
                <a className="anchorLink" href="tel:0800400654">
                  0800 400 654
                </a>
                .
              </Typography>
            </Box>
            <Box className={classes.maxWidth30}>
              <RadioButtonGroup
                control={control}
                name="numberOfVehicles"
                onChange={onChangeVehicleNumber}
                watch={watch}
                defaultValue={numberOfVehicles}
                data={NumberOfVehicles}
              />
              <Typography className="fieldError pt1 pb2">{errors.numberOfVehicles?.message}</Typography>
            </Box>
            <Divider className={clsx(classes.divider, classes.bgBlue, 'mb2')}></Divider>

            {/* What are you looking to cover */}

            {numberOfVehicles !== undefined && (
              <>
                <Box className={clsx(classes.bgLightBlue, 'p1 mb2')}>
                  <Typography variant="h3">What are you looking to cover?</Typography>
                </Box>

                {numberOfVehicles === 'Single' && (
                  <>
                    <Typography className="pb2" variant="body1">
                      Choose an option.
                    </Typography>
                  </>
                )}

                {numberOfVehicles === 'Dual' && (
                  <>
                    <Typography className="pb2" variant="body1">
                      Choose up to two options.
                    </Typography>
                  </>
                )}

                <Box className={classes.maxWidth30}>
                  <SquareRadioGroup
                    vehicleCount={numberOfVehicles === 'Single' ? 1 : 2}
                    name="vehicleCountSquareRadioButtonGroup"
                    onSelectionChange={handleSelectedVehicleChange}
                    selectedValues={{
                      motorHomeCampervan: data.motorHomeCampervan,
                      carCaravan: data.carCaravan,
                      carMotorbike: data.carMotorbike,
                    }}
                    control={control}
                    watch={watch}
                    defaultValue={numberOfVehicles}
                  />
                  <Typography className="fieldError pt1 pb2">
                    {errors.vehicleCountSquareRadioButtonGroup?.message}
                  </Typography>
                </Box>
                <Divider className={clsx(classes.divider, classes.bgBlue, 'my2')}></Divider>
              </>
            )}

            {/*  */}

            {/* */}

            <Box className={clsx(classes.bgLightBlue, 'p1 mb2')}>
              <Typography variant="h3">Do you need breakdown cover at home?</Typography>
            </Box>

            <Typography className="pb2" variant="body1">
              We&apos;ll bring assistance to your doorstep, fixing mechanical problems at home or within 1/4 mile of
              your home address.
            </Typography>

            <Box className={classes.maxWidth30}>
              <RadioButtonGroup
                control={control}
                name="homeCall"
                onChange={onChangeHomeCall}
                watch={watch}
                data={HomeCall}
              />
              <Typography className="fieldError pt1 pb2">{errors.homeCall?.message}</Typography>
            </Box>

            <Divider className={clsx(classes.divider, classes.bgBlue, 'my2')}></Divider>
            {/*  */}

            {/* Which vehicles need cover at home */}
            {data.carCaravan === true && homeCall === 'True' && (
              <>
                <Box className={clsx(classes.bgLightBlue, 'p1 mb2')}>
                  <Typography variant="h3">Which vehicles need cover at home?</Typography>
                </Box>

                <RadioButtonGroup
                  control={control}
                  name="coverAtHomeResults"
                  watch={watch}
                  data={numberOfVehicles === 'Dual' ? CoverAtHomeResultsDual : CoverAtHomeResultsSingle}
                />
                <Typography className="fieldError pt1 pb2">{errors.coverAtHomeResults?.message}</Typography>

                <Divider className={clsx(classes.divider, classes.bgBlue, 'my2')}></Divider>
              </>
            )}
            {/*  */}

            {/* Do you need personal cover? */}
            <Box className={clsx(classes.bgLightBlue, 'p1 mb2')}>
              <Typography variant="h3">Do you need personal cover?</Typography>
            </Box>

            <Typography className="pb1" variant="body1">
              With Personal Cover, you&apos;ll also get the features of your breakdown cover in any private vehicle you
              travel in. It even covers you as a passenger.
            </Typography>

            <Typography className="pb2" variant="body1">
              Your partner will also get this cover if they live at your address.
            </Typography>

            <RadioButtonGroup
              control={control}
              name="personalCover"
              watch={watch}
              onChange={onChangePersonalCover}
              defaultValue={personalCover}
              data={PersonalCover}
            />
            <Typography className="fieldError pt1 pb2">{errors.personalCover?.message}</Typography>

            <Divider className={clsx(classes.divider, classes.bgBlue, 'my2')}></Divider>

            <div className={classes.actionButton}>
              <ButtonPrimary type="submit">Continue</ButtonPrimary>
            </div>
          </form>
        </Grid>
      </Grid>
    </PageTemplate>
  );
};

export default CoverDetails;
