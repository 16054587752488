import React, { useContext, useEffect, useState } from 'react';
import {
  StepChecker,
  DocumentTitle,
  ButtonPrimary,
  Typography,
  Divider,
  ButtonSecondary,
  LinkSecondary,
  AboutYourCoverSimple,
} from '../../components/atoms';
import { Grid, Box } from '@material-ui/core';
import PageTemplate from '../../templates/PageTemplate';
import { useStyles } from './YourCoverStyles';
import { StepContext, steps, Step, IStepData } from '../../contexts/StepContext';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { ComparisonTable } from '../../components/atoms/ComparisonTable';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import clsx from 'clsx';
import { getPersonalCoverPrice, getSecondVehiclePrice, totalCostForAboutYourCover } from '../../utils/coverDetailsUtils';
import { buildAdditionalOptions } from '../../utils/optionNameUtils';

export const YourCover: React.FC = () => {
  const { activeStep, updateActiveStep, data, updateData, updateShowHero, updateShowStepper } = useContext(StepContext);
  const history = useHistory();
  const classes = useStyles();
  const [addAdditionalExtra, setAddAdditionalExtra] = useState('Added for');
  const [showRemoveLink, setShowRemoveLink] = useState<boolean>(true);
  const [selectedCoverOption, setSelectedCoverOption] = useState<string>("");
  //We need a local variable for personal cover because if we make a change to the global one, personal cover disappears.
  const [personalCoverLocal, setPersonalCoverLocal] = useState<string>(data.personalCover);

  useEffect(() => {
    updateActiveStep(2);
    updateShowHero(false);
    updateShowStepper(true);
  }, []);

  const schema = yup.object().shape({
    comparisonTable: yup.string()
      .required('Please select an option'),
  });


  const {
    handleSubmit,
    formState: {
      errors,
    },
    getValues,
    setValue,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      ...data,
      comparisonTable: '',
    },
    shouldFocusError: true,
    shouldUnregister: false,
  });

  const handleAdditionalExtrasAdd = () => {
    setShowRemoveLink(true);
    setAddAdditionalExtra('Added for');
    setPersonalCoverLocal("True");
  };

  const handleRemoveLink = () => {
    setShowRemoveLink(false);
    setAddAdditionalExtra('Add for');
    setPersonalCoverLocal("False");
    setValue('partnerInitial', '');
    setValue('partnerSurname', '');
  };

  const priceDataForTable = data.priceData;
  const hasCaravanBeenselected = data.carCaravan || data.motorHomeCampervan;
  const optionsTableData = data.availableOptionsFromSelections;

  const baseOptionForSubmission = selectedCoverOption?.includes("Roadside & Recovery") ||
    selectedCoverOption?.includes("Premium UK") || selectedCoverOption?.includes("Premium UK PLUS") ? selectedCoverOption : "";

  const priceOfSelectionForAboutYourCover = priceDataForTable.find(item => item.option === baseOptionForSubmission)?.price;

  const handleSelectedCoverOption = (option: string) => {
    setSelectedCoverOption(option);
    setValue('comparisonTable', option);
  };
  const hasAdditionalVehicle:boolean = data.additionalOptions.includes("AdditionalVehicle");

  const calculateTotalCostForAboutYourCover = () => {
    return totalCostForAboutYourCover(priceDataForTable, hasAdditionalVehicle, personalCoverLocal, baseOptionForSubmission, data.options);
  };


  const onSubmit = (stepData: IStepData) => {
    updateActiveStep(activeStep + 1);
    updateData({
      ...data,
      ...stepData,
      ...getValues(),
      personalCover: personalCoverLocal,
      additionalOptions: buildAdditionalOptions(hasAdditionalVehicle === true? 'true':'false', personalCoverLocal),
      //TODO: Will need to test if the following line works. This is needed because the additionalOptions array gets manipulated in the YourDetails page
      //additionalOptions: personalCoverLocal.includes("True") ?data.additionalOptions.push('Personal') : data.additionalOptions,
      baseOption: baseOptionForSubmission,
      coverPrice: calculateTotalCostForAboutYourCover()
    });
    history.push(steps[Step.YOUR_DETAILS].url);
  };

  const handleBack = async () => {
    updateActiveStep(activeStep - 1);
    updateData({
      ...data,
    });
    history.push(steps[Step.COVER_OPTIONS].url + '/' + data.quote);
  };

  // currently its not actually being set, think we need to alter the component to set the value on change
  // this might of been missed when creating the component

  return (
    <PageTemplate>
      <StepChecker />
      <DocumentTitle title={`DLG ${process.env.REACT_APP_SITE_ID?.toUpperCase()} - YourCover`} />
      <Grid container className={classes.stepper}>
        <Box>
          <Grid item xs={12} lg={12} className="pb1">
            <Typography variant="h2">
              Your cover.
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.responsiveHeading}>
            <Typography>
              Based on what you&apos;ve told us, these are the most suitable cover levels for you. We can&apos;t advise which option is best, so take a look through the features, then choose which option you&apos;d like.
            </Typography>
          </Grid>
        </Box>
      </Grid>
      <Grid container className={classes.gridMainContainer}>

        <Box>
          <ComparisonTable
            options={optionsTableData}
            isCaravan={hasCaravanBeenselected}
            priceData={priceDataForTable}
            onSelect={handleSelectedCoverOption} />
          <Typography className="fieldError">{errors.comparisonTable?.message}</Typography>
        </Box>

        <Grid>

        <Typography variant="h5" className="pb1">Not the right cover?</Typography>
        <Typography>
            <a onClick={handleBack} rel="noreferrer" className="smallAnchorLink">
              Change your answers
            </a>
          </Typography>
        </Grid>

        {data.personalCover.includes("True") ?
          <Grid item xs={12}>
            <Box className='mt3'>
              <Box className={clsx(classes.summaryLabel, classes.bgLightBlue)}>
                <Typography variant='h3'>Additional extras</Typography>
              </Box>
              <Grid container className={classes.gridData}>
                <Grid item xs={12} lg={8} className="pr1">
                  <Box className={classes.personalCoverContainer}>
                    <Typography variant="h4" className={classes.personalCoverHeading}>Personal Cover</Typography>
                    <Typography variant="body1" className={classes.personalCoverBody}>With Personal Cover, you will also get the features of your breakdown cover in any private vehicle you travel in. It even overs you as a passenger.</Typography>
                    <Typography variant="body1" className={classes.personalCoverBody}>Your partner will also get this cover if they live at your address.</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} lg={4}>
                  <Box className={classes.summaryContent}>
                    <ButtonSecondary
                      name="personalCover"
                      className={classes.personalCoverBtn}
                      onClick={() => handleAdditionalExtrasAdd()}
                    >
                      {addAdditionalExtra} &#163;32.00
                    </ButtonSecondary>
                  </Box>
                </Grid>
                <Grid item xs={12} lg={8}>
                </Grid>
                <Grid item xs={12} lg={4}>
                  {showRemoveLink ?
                    <Box className={classes.removeLink}>
                      <LinkSecondary onClick={() => handleRemoveLink()}>Remove</LinkSecondary>
                    </Box> : null
                  }
                </Grid>
              </Grid>
              <Divider className={clsx(classes.summaryDivider, classes.bgBlue)}></Divider>
            </Box>
          </Grid>
          : null}
        <Grid item xs={12} className="pt2">
          <Box>
            <AboutYourCoverSimple
              coverLevel={baseOptionForSubmission}
              coverLevelPrice={priceOfSelectionForAboutYourCover}
              hasSecondVehicle={hasAdditionalVehicle}
              secondVehiclePrice={getSecondVehiclePrice(data.options)}
              hasPersonalCover={personalCoverLocal.includes("True") ? true : false}
              personalCoverPrice={getPersonalCoverPrice(data.options)}
              totalCostValue={calculateTotalCostForAboutYourCover()}
            >
            </AboutYourCoverSimple>
          </Box>
        </Grid>
      </Grid>

      <div className="mb2">
        <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
          <Grid container className={clsx(classes.gridMainContainer, "mb2")}>
            <Grid item xs={12} sm={6} className={classes.backButton}>
              <Box>
                <ButtonSecondary onClick={handleBack}>Back</ButtonSecondary>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box className={classes.actionButton}>
                <ButtonPrimary type="submit">
                  Continue
                </ButtonPrimary>
              </Box>
            </Grid>
          </Grid>
        </form>
      </div>
    </PageTemplate>
  );
};

export default YourCover;
