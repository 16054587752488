import React, { createContext, useReducer, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import * as actions from '../actions/StepActions';
import { DLG_DEBUG_STATE_EDITOR_ENABLED, DLG_DEBUG_STATE_EDITOR_DATA } from '../components/atoms/StateEditor';

export enum Step {
  COVER_OPTIONS = 0,
  YOUR_COVER = 1,
  YOUR_DETAILS = 2,
  LETS_REVIEW = 3,
  PAYMENT = 4,
  CONTACT = 5,
}

interface ISteps {
  label: string;
  url: string;
  hidden?: boolean;
  showStepCountLabel?: boolean;
}

export const steps = [
  {
    label: 'Cover Details',
    url: '/coverOptions',
    hidden: false,
  },
  {
    label: 'Question Results',
    url: '/QuestionResults',
    hidden: false,
  },
  {
    label: 'Personal Details',
    url: '/Personal',
    hidden: false,
  },
  {
    label: 'Lets Review',
    url: '/FinalReview',
    hidden: false,
  },
  {
    label: 'Payment',
    url: '/Payment',
    hidden: false,
  },
  {
    label: 'Contact Us',
    url: '/ContactUs',
    hidden: true,
    showStepCountLabel: false,
  },
  {
    label: 'All Sorted',
    url: '/all-sorted',
    hidden: true,
    showStepCountLabel: false,
  },
  {
    label: 'Timeout',
    url: '/timeout',
    hidden: true,
    showStepCountLabel: false,
  },
];

/* StateEditor component initialisation  */
const debugEnabled: string | null = localStorage.getItem(DLG_DEBUG_STATE_EDITOR_ENABLED);
const debugData = debugEnabled === 'true' ? localStorage.getItem(DLG_DEBUG_STATE_EDITOR_DATA) : null;

type PriceData = {
  option: string;
  price: string;
};

export const quoteData = {
  timeout: false,
  quote: '',
  title: '',
  firstName: '',
  lastName: '',
  dateOfBirth: '',
  emailAddress: '',
  phoneNumber: '',
  addressLine1: '',
  addressLine2: '',
  addressLine3: '',
  addressLine4: '',
  addressLine5: '',
  billingAddressLine1: '',
  billingAddressLine2: '',
  billingAddressLine3: '',
  billingAddressLine4: '',
  billingAddressLine5: '',
  billingAddressPostCode: '',
  postcode: '',
  vehicleType: '',
  registrationNumber: '',
  vehicleMake: '',
  vehicleModel: '',
  vehicleAge: 0,
  additionalVehicleRegistrationNumber: '',
  additionalVehicleMake: '',
  additionalVehicleModel: '',
  additionalVehicleAge: 0,
  productName: '',
  personalCover: '',
  homeCall: '',
  baseOption: '',
  coverPrice: null,
  policyStartDate: '',
  policyEndDate: '',
  partnerInitial: '',
  partnerSurname: '',
  automaticRenewal: 'true',
  coverType: '',
  additionalOptions: [''],
  quoteTotal: 0,
  policyType: '',
  thirdPartyPayment: 'No',
  options: null,
  motorHomeCampervan: false,
  carCaravan: false,
  carMotorbike: false,
  priceData: [{ option: '', price: '' }],
  availableOptionsFromSelections: [''],
};

export const paymentData = {
  cardholdersName: '',
  cardNumber: '',
  cardType: '',
  expiryDate: null,
};

export const summaryData = {
  paymentSuccessful: null,
};

export const initialNavStepperData = {
  ...quoteData,
  ...paymentData,
  ...summaryData,
  options: null,
};

export interface IBusinessDetailsAddress {
  firstLineOfAddress: string;
  secondLineOfAddress: string;
  town: string;
  county: string;
  postcode: string;
}

export interface IStepData {
  options: any;
  quote: string | number;
  title: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string | Date;
  emailAddress: string;
  phoneNumber: number | string;
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  addressLine4: string;
  addressLine5: string;
  billingAddressLine1: string;
  billingAddressLine2: string;
  billingAddressLine3: string;
  billingAddressLine4: string;
  billingAddressLine5: string;
  billingAddressPostCode: string;
  postcode: string;
  vehicleType: string;
  registrationNumber: string;
  vehicleMake: string;
  vehicleModel: string;
  vehicleAge: number;
  additionalVehicleRegistrationNumber: string;
  additionalVehicleMake: string;
  additionalVehicleModel: string;
  additionalVehicleAge: number;
  productName: string;
  personalCover: string;
  homeCall: string | undefined;
  coverPrice: null | number;
  policyStartDate: Date | string | null;
  policyEndDate: Date | string | null;
  partnerInitial: string;
  partnerSurname: string;
  coverType: string;
  policyType: string;
  automaticRenewal: string;
  cardholdersName: string;
  cardNumber: number | string;
  cardType: string;
  expiryDate: Date | null;
  quoteTotal: number;
  paymentSuccessful: boolean | null;
  baseOption: string;
  additionalOptions;
  thirdPartyPayment: string;
  motorHomeCampervan: boolean;
  carCaravan: boolean;
  carMotorbike: boolean;
  priceData: PriceData[];
  availableOptionsFromSelections;
}

export interface IStep {
  activeStep: number;
  steps: Array<ISteps>;
  data: IStepData;
  loading: boolean;
  showStepper: boolean;
  showHero: boolean;
}

const initialState = {
  activeStep: debugData ? JSON.parse(debugData).activeStep : 1,
  steps: steps,
  data: debugData ? JSON.parse(debugData).data : initialNavStepperData,
  loading: true,
  showStepper: true,
  showHero: true,
  updateLoading: () => {},
  updateQuotes: () => {},
  updateActiveStep: () => {},
  updateData: () => {},
  updateShowStepper: () => {},
  updateShowHero: () => {},
};

export type StepType = {
  activeStep: number;
  steps: Array<ISteps>;
  data: IStepData;
  loading: boolean;
  showStepper: boolean;
  showHero: boolean;
  updateLoading: (isLoading: boolean) => void;
  updateQuotes: (quotes) => void;
  updateActiveStep: (newCount: number) => void;
  updateData: (newStepData: IStepData) => void;
  updateShowStepper: (showStepper: boolean) => void;
  updateShowHero: (showHero: boolean) => void;
};

export const StepContext = createContext<StepType>(initialState);

const reducer = (state: IStep, action: actions.Action) => {
  switch (action.type) {
    case actions.LOADING:
      return { ...state, loading: action.payload };
    case actions.UPDATE_QUOTES:
      return { ...state, data: { ...state.data, coverOptions: action.payload } };
    case actions.UPDATE_ACTIVE_STEP:
      return { ...state, activeStep: action.payload };
    case actions.UPDATE_DATA:
      return { ...state, data: { ...initialNavStepperData, ...action.payload } };
    case actions.UPDATE_SHOW_STEPPER:
      return { ...state, showStepper: action.payload };
    case actions.UPDATE_SHOW_HERO:
      return { ...state, showHero: action.payload };
    default:
      return state;
  }
};

export const StepProvider = ({ children }: JSX.ElementChildrenAttribute): JSX.Element => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const history = useHistory();
  const sessionTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  // Exclude ages from triggering timeout
  const excludedTimeout = ['guide', 'timeout', 'all-sorted'];

  const resetSessionTimeout = () => {
    // preventing users from accessing pages without quote data
    if (!excludedTimeout.some((path) => window.location.pathname.includes(path)) && state.data?.timeout) {
      resetData();
    }
    sessionTimeoutRef.current = setTimeout(resetData, 15 * 60 * 1000);

    if (excludedTimeout.some((path) => window.location.pathname.includes(path)) && sessionTimeoutRef.current !== null) {
      clearTimeout(sessionTimeoutRef.current);
    }
  };

  const resetData = () => {
    dispatch({ type: actions.LOADING, payload: true });
    dispatch({ type: actions.UPDATE_DATA, payload: { quoteData, timeout: true } });

    if (!state.data?.quote && state.data.timeout) {
      history.replace('/timeout');
    }
  };

  useEffect(() => {
    resetSessionTimeout();

    return () => {
      if (sessionTimeoutRef.current !== null) {
        clearTimeout(sessionTimeoutRef.current);
      }
    };
  }, [state]);

  return (
    <StepContext.Provider
      value={{
        activeStep: state.activeStep,
        data: state.data,
        steps: state.steps,
        loading: state.loading,
        showStepper: state.showStepper,
        showHero: state.showHero,
        updateLoading: (isLoading: boolean) => dispatch({ type: actions.LOADING, payload: isLoading }),
        updateQuotes: (quotes) => dispatch({ type: actions.UPDATE_QUOTES, payload: quotes }),
        updateActiveStep: (newStep: number) => dispatch({ type: actions.UPDATE_ACTIVE_STEP, payload: newStep }),
        updateData: (newStepData: IStepData) => dispatch({ type: actions.UPDATE_DATA, payload: newStepData }),
        updateShowStepper: (showStepper: boolean) =>
          dispatch({ type: actions.UPDATE_SHOW_STEPPER, payload: showStepper }),
        updateShowHero: (showHero: boolean) => dispatch({ type: actions.UPDATE_SHOW_HERO, payload: showHero }),
      }}
    >
      {children}
    </StepContext.Provider>
  );
};

export default StepProvider;
