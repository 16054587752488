import React, { useContext, useEffect } from 'react';
import {
  StepChecker,
  DocumentTitle,
  Typography,
  Accordion,
  ButtonSecondary,
  Divider,
  ButtonPrimary,
  AboutYourCover,
  DatePicker,
  TextField,
  RadioButtonGroup,
  Checkbox,
} from '../../components/atoms';
import { VehicleLookUp } from '../../components/molecules/VehicleLookup/VehicleLookUp';
import { AdditionalVehicleLookUp } from '../../components/molecules/AdditionalVehicleLookup/AdditionalVehicleLookUp';
import { Grid, Box, FormControl, InputLabel } from '@material-ui/core';
import PageTemplate from '../../templates/PageTemplate';
import { useStyles } from './YourDetailsStyles';
import { StepContext, steps, Step, IStepData } from '../../contexts/StepContext';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import importantInfoSchema from './YourDetailsSchema';
import * as yup from 'yup';
import clsx from 'clsx';
import { PostcodeLookupWrapper } from '../../components/molecules';
import { addDays, startOfDay, format, subDays, addYears } from 'date-fns';
import { formatDate } from '../../utils/dateFormattingUtils';
import { getPersonalCoverPrice, getSecondVehiclePrice } from '../../utils/coverDetailsUtils';

export const YourDetails: React.FC = () => {
  const { activeStep, updateActiveStep, data, updateData, updateShowHero, updateShowStepper } = useContext(StepContext);
  const history = useHistory();
  const classes = useStyles();

  const minimumStartDate = addDays(startOfDay(new Date()), 1);
  const maximumStartDate = addDays(new Date(), 179);

  useEffect(() => {
    updateActiveStep(3);
    updateShowHero(false);
    updateShowStepper(true);
  }, []);

  const hasPersonalCover = data.personalCover.includes('True') ? true : false;
  const hasAdditionalVehicle: boolean = data.additionalOptions.includes("AdditionalVehicle");
  const hasAdditionalVehicleYup = data.additionalOptions.includes("AdditionalVehicle") ? 'true' : 'false';

  const schema = yup.object().shape({
    coverStartDate: yup
      .date()
      .typeError('Please select your cover start date.')
      .required('Please select your cover start date.')
      .min(new Date(minimumStartDate), 'You cannot choose a day before today.')
      .max(new Date(maximumStartDate), 'The start date must be between tomorrow and six months from now.'),
    vehicleLookUp: yup
      .object()
      .shape({
        registrationNumber: yup
          .string()
          .matches(/^(?! )[A-Za-z0-9 ]+$/, 'Please enter a valid registration.')
          .required('Let us know your registration number.'),
      }),
    additionalVehicleLookUp: yup
      .object()
      .when(hasAdditionalVehicleYup, {
        is: () => hasAdditionalVehicleYup === 'true',
        then: yup
          .object()
          .shape({
            additionalVehicleRegistrationNumber: yup
              .string()
              .matches(/^(?! )[A-Za-z0-9 ]+$/, 'Please enter a valid registration.')
              .test('same-registration', 'Please enter a different registration number.', function (value) {
                return value?.toUpperCase() !== getValues('vehicleLookUp.registrationNumber');
              })
              .required('Let us know your registration number.')
          }),
      }),
    firstNameField: yup
      .string()
      .required("Enter your first name."),
    lastNameField: yup
      .string()
      .required("Enter your last name."),
    postcodeLookup: yup
      .object()
      .shape({
        firstLineOfAddress: yup.string().required('Please select an address.'),
        secondLineOfAddress: yup.string(),
        thirdLineOfAddress: yup.string(),
        town: yup.string().required('Please select an address.'),
        county: yup.string(),
        postcode: yup.string().required('Please select an address.'),
      }),
    emailAddressField: yup
      .string()
      .email('Please enter a valid email address.')
      .required("Let us know your email address."),
    contactNumberField: yup
      .string()
      .transform((value) => (isNaN(value) ? undefined : value))
      .matches(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/, 'Let us know your contact number.')
      .required("Please enter a valid contact number."),
    partnerFirstInitialField: yup
      .string(),
    partnerSecondNameField: yup
      .string(),
    importantStatementsConfirmation: yup
      .bool()
      .oneOf([true], 'Please confirm the above to continue.')
      .required("Please confirm the above to continue."),
    partnerInitialsField: yup
      .string(),
    partnerLastNameField: yup
      .string()
  });

  const {
    handleSubmit,
    control,
    formState: {
      errors,
    },
    watch,
    trigger,
    getValues,
    setValue,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      ...data,
      meetsVehicleCriteria: false,
      showLookup: true,
      coverStartDate: data.policyStartDate ? data.policyStartDate : null,
      vehicleLookUp: {
        vehicleMake: data.vehicleMake,
        vehicleModel: data.vehicleModel,
        vehicleYear: (new Date().getFullYear() - data.vehicleAge),
        registrationNumber: data.registrationNumber !== '' ? data.registrationNumber : '',
      },
      additionalVehicleLookUp: {
        additionalVehicleMake: data.additionalVehicleMake,
        additionalVehicleModel: data.additionalVehicleModel,
        additionalVehicleYear: (new Date().getFullYear() - data.additionalVehicleAge),
        additionalVehicleRegistrationNumber: data.additionalVehicleRegistrationNumber !== '' ? data.additionalVehicleRegistrationNumber : '',
      },
      postcodeLookup: {
        firstLineOfAddress: data.addressLine1,
        secondLineOfAddress: data.addressLine2,
        thirdLineOfAddress: data.addressLine3,
        town: data.addressLine4,
        county: data.addressLine5,
        postcode: data.postcode,
      },
      firstNameField: data.firstName,
      lastNameField: data.lastName,
      emailAddressField: data.emailAddress,
      contactNumberField: data.phoneNumber,
      partnerInitialsField: data.partnerInitial,
      partnerLastNameField: data.partnerSurname,
      renewAutomatically: data.automaticRenewal,
      importantStatementsConfirmation: false,
    },
    shouldFocusError: true,
    shouldUnregister: false,
  });

  function showSpecialCharacterWarning(lookupValue) {
    if (lookupValue && !lookupValue.match(/^[A-Za-z0-9 ]+$/)) {
      return true;
    } else {
      return false;
    }
  }

  const vlMake = watch('vehicleLookUp.vehicleMake');
  const vlModel = watch('vehicleLookUp.vehicleModel');
  const vlYear = watch('vehicleLookUp.vehicleYear');
  const vlRegistrationNumber = watch('vehicleLookUp.registrationNumber');

  const avlMake = watch('additionalVehicleLookUp.additionalVehicleMake');
  const avlModel = watch('additionalVehicleLookUp.additionalVehicleModel');
  const avlYear = watch('additionalVehicleLookUp.additionalVehicleYear');
  const avlRegistrationNumber = watch('additionalVehicleLookUp.additionalVehicleRegistrationNumber');

  const autoRenewalValue = watch('renewAutomatically');

  const plAddressLine1 = getValues('postcodeLookup.firstLineOfAddress');
  const plAddressLine2 = getValues('postcodeLookup.secondLineOfAddress');
  const plAddressLine3 = getValues('postcodeLookup.thirdLineOfAddress');
  const plAddressLine4 = getValues('postcodeLookup.town');
  const plAddressLine5 = getValues('postcodeLookup.county');
  const plPostcode = watch('postcodeLookup.postcode');

  const onSubmit = (stepData: IStepData) => {
    
    const formattedPolicyStartDate = format(new Date(formatDate(getValues('coverStartDate'))), 'yyyy/MM/dd');

    updateActiveStep(activeStep + 1);
    updateData({
      ...data,
      ...stepData,
      ...getValues(),
      policyStartDate: formattedPolicyStartDate,
      policyEndDate: format(subDays(addYears(new Date(formattedPolicyStartDate), 1), 1), 'yyyy/MM/dd'),
      vehicleMake: vlMake,
      vehicleModel: vlModel ? vlModel : ' ',
      vehicleAge: (new Date().getFullYear() - Number(vlYear)),
      firstName: getValues('firstNameField'),
      lastName: getValues('lastNameField'),
      partnerInitial: getValues('partnerInitialsField'),
      partnerSurname: getValues('partnerLastNameField'),
      registrationNumber: vlRegistrationNumber.toUpperCase(),
      additionalVehicleRegistrationNumber: avlRegistrationNumber.toUpperCase(),
      additionalVehicleMake: avlMake,
      additionalVehicleModel: avlModel ? avlModel : ' ',
      additionalVehicleAge: (new Date().getFullYear() - Number(avlYear)),
      addressLine1: plAddressLine1,
      addressLine2: plAddressLine2,
      addressLine3: plAddressLine3,
      addressLine4: plAddressLine4,
      addressLine5: plAddressLine5,
      postcode: plPostcode,
      emailAddress: getValues('emailAddressField'),
      phoneNumber: getValues('contactNumberField'),
      automaticRenewal: autoRenewalValue,
    });
    history.push(steps[Step.LETS_REVIEW].url);
  };

  const handleBack = async () => {
    updateActiveStep(activeStep - 1);
    updateData({
      ...data,
    });
    history.push(steps[Step.YOUR_COVER].url);
  };

  const {
    PersonalCoverPartner,
    renewAuto,
    RenewAutomaticallyRadioData,
    InsuranceProductInformation,
    importantStatements,
    autoRenewal,
    yourRightToCancel,
    howToComplain,
    informationUsage,
  } = importantInfoSchema;

  return (
    <PageTemplate>
      <StepChecker />
      <DocumentTitle title={`DLG ${process.env.REACT_APP_SITE_ID?.toUpperCase()} - Your Details`} />
      <Grid container className={classes.stepper}>
        <Box>
          <Grid item xs={12} lg={12} className="pb1">
            <Typography variant="h2">
              Your details.
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.responsiveHeading}>
            <Typography variant="body1">
              Let us know a few details, then check over some important information from us.
            </Typography>
          </Grid>
        </Box>
      </Grid>
      <Grid className={classes.gridMainContainer}>
        <AboutYourCover
          coverLevel={data.baseOption}
          coverLevelPrice={data.priceData.find(item => item.option === data.baseOption)?.price}
          hasSecondVehicle={hasAdditionalVehicle}
          secondVehiclePrice={getSecondVehiclePrice(data.options)}
          hasPersonalCover={hasPersonalCover}
          personalCoverPrice={getPersonalCoverPrice(data.options)}
          totalCostValue={data.coverPrice ? data.coverPrice : 0}
        >
        </AboutYourCover>
      </Grid>
      <Grid container className={clsx(classes.gridMainContainer, "mt2")}>
        <Grid item xs={12}>
          <Typography variant="h3">
            When do you want your cover to start?
          </Typography>
        </Grid>
        <Grid item xs={12} className="pt2">
          <Typography variant="body2">
            Cover start date
          </Typography>
          <DatePicker
            className={classes.datePicker}
            disablePast={true}
            id="coverStartDate"
            name="coverStartDate"
            defaultValue={data.policyStartDate}
            control={control}
            minDate={minimumStartDate}
            maxDate={maximumStartDate}
          />
          <Typography className="fieldError">{errors.coverStartDate?.message}</Typography>
        </Grid>
      </Grid>
      <Grid container className={classes.gridMainContainer}>
        <Grid item xs={12}>
          <Typography variant="h3">
            Your details
          </Typography>
        </Grid>
        <Grid item xs={12} className="py1">
          <Typography variant="body1">
            You only need to enter details about cars, motorbikes, motorhomes, or campervans here. Don&apos;t add details about any caravans.
          </Typography>
        </Grid>
        <Grid item xs={12} className="pt2">
          <InputLabel className={classes.registrationNumberLabel} htmlFor="registrationNumber">
            Vehicle {hasAdditionalVehicle === true ? "one" : ""} registration number
          </InputLabel>
          <Box>
            <VehicleLookUp
              vehicleMake={vlMake}
              vehicleModel={vlModel}
              vehicleYear={vlYear}
              registrationNumber={vlRegistrationNumber}
              vlName="vehicleLookUp.registrationNumber"
              setValue={setValue}
              trigger={trigger}
              control={control}
            />
            {errors.vehicleLookUp && (
              <Typography className="fieldError">
                {errors.vehicleLookUp?.registrationNumber
                  ? errors.vehicleLookUp?.registrationNumber.message
                  : ''}
              </Typography>
            )}
          </Box>
        </Grid>
        {hasAdditionalVehicle === true ? (
          <Grid item xs={12} className="pt2">
            <InputLabel className={classes.registrationNumberLabel} htmlFor="additionalVehicleRegistrationNumber">
              Vehicle two registration number
            </InputLabel>
            <Box>
              <AdditionalVehicleLookUp
                vehicleMake={avlMake}
                vehicleModel={avlModel}
                vehicleYear={avlYear}
                registrationNumber={avlRegistrationNumber}
                vlName="additionalVehicleLookUp.additionalVehicleRegistrationNumber"
                setValue={setValue}
                trigger={trigger}
                control={control}
                vehicleOneRegistrationNumber={vlRegistrationNumber}
              />

              {errors.additionalVehicleLookUp && (
                <Typography className="fieldError">
                  {errors.additionalVehicleLookUp?.additionalVehicleRegistrationNumber
                    ? errors.additionalVehicleLookUp?.additionalVehicleRegistrationNumber.message
                    : ''}
                </Typography>
              )}
            </Box>
          </Grid>
        ) : <></>}
      </Grid>
      <Grid container className={classes.gridMainContainer}>
        <Grid item xs={12}>
          <Typography variant="h3">
            About you
          </Typography>
        </Grid>
        <Grid item xs={12} className="pt2">
          <Box>
            <Typography variant="body2">
              First name
            </Typography>
            <TextField
              className={clsx(classes.minWidth20, "pt1")}
              id="firstNameField"
              name="firstNameField"
              control={control}
            />
            <Typography className="fieldError">{errors.firstNameField?.message}</Typography>
          </Box>
          <Box>
            <Typography className="pt2" variant="body2">
              Last name
            </Typography>
            <TextField
              className={clsx(classes.minWidth20, "pt1")}
              id="lastNameField"
              name="lastNameField"
              control={control}
            />
            <Typography className="fieldError">{errors.lastNameField?.message}</Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid container className={classes.gridMainContainer}>
        <Grid item xs={12} className="mt2">
          <Typography variant="h3">
            Your address
          </Typography>
        </Grid>
      </Grid>
      <Grid container className={classes.gridMainContainer}>
        <Grid item xs={12}>
          <PostcodeLookupWrapper
            addressLine1={plAddressLine1}
            addressLine2={plAddressLine2}
            addressLine3={plAddressLine3}
            addressLine4={plAddressLine4}
            addressLine5={plAddressLine5}
            postcode={plPostcode}
            plName="postcodeLookup.postcode"
            plPostcode={plPostcode}
            setValue={setValue}
            trigger={trigger}
            control={control}
          />

          {(showSpecialCharacterWarning(plPostcode) == true) &&
            <Typography className="fieldError">Please enter a valid postcode.</Typography>
          }

          {errors.postcodeLookup && (
            <Typography className="fieldError">
              {errors.postcodeLookup?.firstLineOfAddress
                ? errors.postcodeLookup?.firstLineOfAddress.message
                : errors.postcodeLookup?.town
                  ? errors.postcodeLookup?.town.message
                  : errors.postcodeLookup?.postcode
                    ? errors.postcodeLookup.postcode.message
                    : ''}
            </Typography>
          )}
        </Grid>
      </Grid>
      <Grid container className={classes.gridMainContainer}>
        <Grid item xs={12} className="mt2">
          <Typography variant="h3">
            Contact details
          </Typography>
        </Grid>
        <Grid item xs={12} className="pt2">
          <Typography variant="body2">
            Email address
          </Typography>
          <TextField
            className={clsx(classes.minWidth20, "pt1")}
            id="emailAddressField"
            name="emailAddressField"
            control={control}
          />
          <Typography className="fieldError">{errors.emailAddressField?.message}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className="pt1" variant="body2">
            Contact number
          </Typography>
          <TextField
            className={clsx(classes.minWidth20, "pt1")}
            id="contactNumberField"
            name="contactNumberField"
            control={control}
          />
          <Typography className="fieldError">{errors.contactNumberField?.message}</Typography>

        </Grid>
      </Grid>
      {(hasPersonalCover) ? (
        <Grid container className={classes.gridMainContainer}>
          <Grid item xs={12}>
            <Typography variant="h3">
              Personal Cover - add your partner (optional)
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} className="pt1">
            <Typography>
              {PersonalCoverPartner.body}
            </Typography>
          </Grid>
          <Grid item xs={12} className="pt2">
            <Box>
              <Typography className="pt3" variant="body2">
                Partner&apos;s first initial
              </Typography>
              <TextField
                className={clsx(classes.minWidth20, "pt1")}
                id="partnerInitialsField"
                name="partnerInitialsField"
                control={control}
              />
            </Box>
            <Box>
              <Typography className="pt3" variant="body2">
                Partner&apos;s last name
              </Typography>
              <TextField
                className={clsx(classes.minWidth20, "pt1")}
                id="partnerLastNameField"
                name="partnerLastNameField"
                control={control}
              />
            </Box>
          </Grid>
        </Grid>
      ) : <></>}

      <Grid container className={classes.gridMainContainer}>
        <Grid item xs={12}>
          <Box className={clsx(classes.yourCoverLabel, classes.bgLightBlue)}>
            <Typography variant="h3">Stay covered, without having to get in touch.</Typography>
          </Box>
          <Box className="pl1 pt1">
            {renewAuto.body}
          </Box>
          <Box className="pt2">
            <RadioButtonGroup
              className="pl1"
              control={control}
              name="renewAutomatically"
              watch={watch}
              data={RenewAutomaticallyRadioData}
            />
          </Box>
          <Divider className={classes.divider}></Divider>
        </Grid>
      </Grid>
      <Grid container className={classes.gridMainContainer}>
        <Grid item xs={12} className="pt3">
          <>
            {InsuranceProductInformation.heading}
          </>
          <div className="pb1">
            {InsuranceProductInformation.body}
          </div>
          <Divider className={classes.divider}></Divider>
        </Grid>
      </Grid>
      <Grid container className={classes.gridMainContainer}>
        <Grid item xs={12} lg={12} className={classes.gridMain}>
          <Box className="pt2">
            <div className="pb1">
              <Accordion heading={importantStatements.heading} body={importantStatements.body} />
            </div>
            <div className="pb1">
              <Accordion heading={autoRenewal.heading} body={autoRenewal.body} />
            </div>
            <div className="pb1">
              <Accordion heading={yourRightToCancel.heading} body={yourRightToCancel.body} />
            </div>
            <div className="pb1">
              <Accordion heading={howToComplain.heading} body={howToComplain.body} />
            </div>
          </Box>
        </Grid>
        <Grid container className={classes.gridMainContainer}>
          <Grid item xs={12} className="pt3">
            <Typography variant="h4" className="pb2">
              Confirm you have read the important statements
            </Typography>
            <>
              <FormControl component="fieldset" className="minWidth100">
                <Checkbox
                  name="importantStatementsConfirmation"
                  watch={watch}
                  control={control}
                  trigger={trigger}
                  label="I confirm that all the information and above statements are true."
                  color="primary"
                  error={errors.importantStatementsConfirmation ? true : false}
                />
                {errors.importantStatementsConfirmation && <p className="fieldError">{errors.importantStatementsConfirmation.message}</p>}
              </FormControl>
            </>
            <Divider className={classes.divider}></Divider>
          </Grid>
        </Grid>
        <Grid container className={classes.gridMainContainer}>
          <Grid item xs={12} className="pb2">
            <Typography variant="h4">
              How we&apos;ll use your information
            </Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <div className="pb1">
              {informationUsage.para1}
            </div>
            <div className="pb1">
              {informationUsage.para2}
            </div>
            <>
              {informationUsage.para3}
            </>
          </Grid>
          <Grid item xs={12} className="pt1">
            <Divider className={classes.divider}></Divider>
          </Grid>
        </Grid>
      </Grid>
      <div className="mb2">
        <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
          <Grid container className={clsx(classes.gridMainContainer, "mb2")}>
            <Grid item xs={12} sm={6} className={classes.backButton}>
              <Box>
                <ButtonSecondary onClick={handleBack}>Back</ButtonSecondary>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box className={classes.actionButton}>
                <ButtonPrimary type="submit">
                  Continue
                </ButtonPrimary>
              </Box>
            </Grid>
          </Grid>
        </form>
      </div>
    </PageTemplate>
  );
};

export default YourDetails;
